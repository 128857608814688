<template>
  <base-layout>
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <div>
            <title-plus title="Refund Request" :hide-plus="true" />
          </div>

          <div class="flex md:justify-end">
            <date-range-picker
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>
      </div>
      <template v-if="$acl.canView('transaction')">
        <FSTable
          :fst-id="`refundRequest`"
          :endpoint="getEndpoint"
          :headers="getTableHeaders"
          exportFor="refundRequest"
        >
          <template v-slot:default="{ data }">
            <template v-for="(item, itemIndex) in data">
              <template v-if="getTableMode === 'full'">
                <FSTableRow
                  :key="itemIndex"
                  @meta="(e) => (indexMetaData = e)"
                  text-fallback-always
                >
                  <FSTableRowItem :text="item.id" :truncate="-5" />

                  <FSTableRowItem>
                    <router-link
                      v-if="item.rider"
                      :to="{
                        name: 'ViewRiderUserProfile',
                        params: { id: item.rider.id },
                      }"
                      class="font-extrabold text-blue-600 capitalize"
                      target="_blank"
                    >
                      {{ item.rider.full_name }}
                    </router-link>
                    <span v-else>
                      --
                    </span>
                  </FSTableRowItem>

                  <FSTableRowItem
                    :text="`${item.currency.symbol}${item.requested_amount}`"
                  />

                  <FSTableRowItem
                    :text="
                      item.refunded_amount
                        ? `${item.currency.symbol}${item.refunded_amount}`
                        : '--'
                    "
                  />
                  <FSTableRowItem
                    :text="
                      item.refundable_amount
                        ? `${item.currency.symbol}${item.refundable_amount}`
                        : '--'
                    "
                  />

                  <FSTableRowItem>
                    <template v-if="item.reference_ids">
                      <span
                        v-for="(refId, refIdIndex) in item.reference_ids"
                        :key="refIdIndex"
                        class="text-blue-500 px-1"
                      >
                        <a
                          target="_blank"
                          :href="
                            `https://dashboard.stripe.com/payments/${refId}`
                          "
                          >{{ truncate(refId, -5, '') }}</a
                        >
                        <span
                          class="cursor-pointer text-gray-500"
                          @click="
                            copyTextToClipboard(
                              `https://dashboard.stripe.com/payments/${refId}`
                            )
                          "
                        >
                          <i class="ml-2 far fa-copy"></i>
                        </span>
                      </span>
                    </template>

                    <span v-else>--</span>
                  </FSTableRowItem>

                  <FSTableRowItem :text="item.created_at" date />
                  <FSTableRowItem>
                    <XStatus
                      :text="item.status_display_text"
                      :variant="getVariant(item.status)"
                    />
                  </FSTableRowItem>
                  <FSTableRowItem>
                    <div class="flex items-center " v-if="item.status === 0">
                      <template>
                        <t-dropdown variant="smActions">
                          <template
                            #trigger="{
                        mousedownHandler,
                        focusHandler,
                        blurHandler,
                        keydownHandler,
                    }"
                          >
                            <button
                              class="focus:outline-none"
                              aria-label="More Actions"
                              aria-haspopup="true"
                              @mousedown="mousedownHandler"
                              @focus="focusHandler"
                              @blur="blurHandler"
                              @keydown="keydownHandler"
                            >
                              <svg
                                class="w-6 h-5 mt-1 text-oGray hover:text-oDark"
                                fill="none"
                                stroke="currentColor"
                                viewBox="0 0 24 24"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  stroke-width="2"
                                  d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                                ></path>
                              </svg>
                            </button>
                          </template>

                          <template #default="{ hide }">
                            <div
                              @click="hide()"
                              class="py-1 mt-px bg-white rounded-md shadow-md cursor-pointer"
                            >
                              <t-dropdown-item @click="onRefundReq(item)">
                                Refund
                              </t-dropdown-item>
                            </div>
                          </template>
                        </t-dropdown>
                      </template>
                    </div>
                    <div v-else>--</div>
                  </FSTableRowItem>
                </FSTableRow>
              </template>
              <template v-if="getTableMode === 'responsive'">
                <FSTableRow
                  :key="`fs-table-row-${itemIndex}`"
                  textFallbackAlways
                >
                  <FSTableRowItem>
                    <div
                      class="col-span-1 focus:text-gray-400"
                      @click="toggle(itemIndex)"
                    >
                      <i
                        class="fas fa-minus-circle"
                        style="color:#d90a20;"
                        v-if="opened.includes(itemIndex)"
                      ></i>
                      <i class="fas fa-plus-circle" v-else></i>
                    </div>
                  </FSTableRowItem>

                  <FSTableRowItem>
                    <router-link
                      v-if="item.rider"
                      :to="{
                        name: 'ViewRiderUserProfile',
                        params: { id: item.rider.id },
                      }"
                      class="font-extrabold text-blue-600 capitalize"
                      target="_blank"
                    >
                      {{ item.rider.full_name }}
                    </router-link>
                    <span v-else>
                      --
                    </span>
                  </FSTableRowItem>
                  <FSTableRowItem
                    :text="`${item.currency.symbol}${item.requested_amount}`"
                  />
                  <FSTableRowItem>
                    <XStatus
                      :text="item.status_display_text"
                      :variant="getVariant(item.status)"
                    />
                  </FSTableRowItem>
                </FSTableRow>
                <FSTableRow v-if="opened.includes(itemIndex)" :key="itemIndex">
                  <td colspan="10">
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{ $t('ID') }}
                      </div>
                      <div class="col-span-5 right-text">
                        {{ item.id | onlyLastFive }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{ $t('Refunded Amount') }}
                      </div>
                      <div class="col-span-5 right-text">
                        ${{ $t(item.refunded_amount) }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{ $t('Refundable Amount') }}
                      </div>
                      <div class="col-span-5 right-text">
                        {{
                          item.refundable_amount
                            ? '$' + $t(item.refundable_amount)
                            : '--'
                        }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{ $t('Payment Ref') }}
                      </div>
                      <div class="col-span-5 right-text">
                        <template v-if="item.reference_ids">
                          <span
                            v-for="(refId, refIdIndex) in item.reference_ids"
                            :key="refIdIndex"
                            class="text-blue-500 px-1"
                          >
                            <a
                              target="_blank"
                              :href="
                                `https://dashboard.stripe.com/payments/${refId}`
                              "
                              >{{ truncate(refId, -5, '') }}</a
                            >
                            <span
                              class="cursor-pointer text-gray-500"
                              @click="
                                copyTextToClipboard(
                                  `https://dashboard.stripe.com/payments/${refId}`
                                )
                              "
                            >
                              <i class="ml-2 far fa-copy"></i>
                            </span>
                          </span>
                        </template>

                        <span v-else>--</span>
                      </div>
                    </div>

                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{ $t('Request at') }}
                      </div>
                      <div class="col-span-5 right-text">
                        {{ item.created_at | friendlyDateTime }}
                      </div>
                    </div>
                    <div
                      class="grid grid-cols-10 items-center w-full expand-item"
                    >
                      <div class="col-span-5 left-text">
                        {{ $t('Action') }}
                      </div>
                      <div class="col-span-5 right-text">
                        <div
                          class="flex items-center "
                          v-if="item.status === 0"
                        >
                          <template>
                            <t-dropdown variant="smActions">
                              <template
                                #trigger="{
                        mousedownHandler,
                        focusHandler,
                        blurHandler,
                        keydownHandler,
                    }"
                              >
                                <button
                                  class="focus:outline-none"
                                  aria-label="More Actions"
                                  aria-haspopup="true"
                                  @mousedown="mousedownHandler"
                                  @focus="focusHandler"
                                  @blur="blurHandler"
                                  @keydown="keydownHandler"
                                >
                                  <svg
                                    class="w-6 h-5 mt-1 text-oGray hover:text-oDark"
                                    fill="none"
                                    stroke="currentColor"
                                    viewBox="0 0 24 24"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      stroke-linecap="round"
                                      stroke-linejoin="round"
                                      stroke-width="2"
                                      d="M12 5v.01M12 12v.01M12 19v.01M12 6a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2zm0 7a1 1 0 110-2 1 1 0 010 2z"
                                    ></path>
                                  </svg>
                                </button>
                              </template>

                              <template #default="{ hide }">
                                <div
                                  @click="hide()"
                                  class="py-1 mt-px bg-white rounded-md shadow-md cursor-pointer"
                                >
                                  <t-dropdown-item @click="onRefundReq(item)">
                                    Refund
                                  </t-dropdown-item>
                                </div>
                              </template>
                            </t-dropdown>
                          </template>
                        </div>
                        <div v-else>--</div>
                      </div>
                    </div>
                  </td>
                </FSTableRow>
              </template>
            </template>
          </template>
        </FSTable>
      </template>

      <div v-else class="py-5 font-bold text-center text-gray-600">
        {{ $t('components.acl.doNotHavePermission') }}
      </div>
    </content-section>
    <RefundRequestPopup @refresh="$store.dispatch('fsTable/fetchData')" />
  </base-layout>
</template>
<script>
import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  onApplyFilterDateRange,
} from '@/components/fs-table'
import { useEndpoints } from '@/composables'
import { RefundRequestConfig } from '@/config/RefundRequestConfig'
import { truncate } from '@/plugins/truncate'
import { EventBus } from '@/utils'
export default {
  name: 'RefundRequestIndex',
  components: {
    BaseLayout: () => import('@/views/shared/BaseLayout.vue'),
    ContentSection: () => import('@/components/layout/ContentSection'),
    TitlePlus: () => import('@/components/ui/TitlePlus'),
    DateRangePicker: () =>
      import('@/components/picker/date-range/DateRangePicker'),
    XStatus: () => import('@/components/badge/XStatus.vue'),
    RefundRequestPopup: () => import('@/views/transactions/RefundRequestPopup'),
    TDropdownItem: () => import('@/components/dropdown/TDropdownItem'),

    FSTable,
    FSTableRow,
    FSTableRowItem,
  },

  computed: {
    getTableHeaders() {
      if (this.windowWidth <= 930) {
        return this.resTableHeaders
      } else {
        return this.tableHeaders
      }
    },
    getTableMode() {
      if (this.windowWidth <= 930) {
        return 'responsive'
      } else {
        return 'full'
      }
    },
    getEndpoint() {
      return useEndpoints.refundApplication.index()
    },
  },
  data() {
    return {
      symbol: '$',
      popupData: {},
      statusMap: {
        0: 'Pending',
        1: 'Processed',
        2: 'Revoked',
        3: 'Rejected',
      },
      windowWidth: window.innerWidth,
      opened: [],
      tableHeaders: [
        {
          text: 'Id',
          width: '5%',
          sort: null,
        },
        {
          text: 'Rider Name',
          width: '10%',
          sort: null,
        },

        {
          text: 'Requested Amount',
          width: '10%',
          sort: null,
        },
        {
          text: 'Refunded Amount',
          width: '10%',
          sort: null,
        },
        {
          text: 'Refundable Amount',
          width: '10%',
          sort: null,
        },
        {
          text: 'Payment Ref',
          width: '10%',
          sort: null,
        },
        {
          text: 'Request at',
          width: '12%',
          sort: null,
        },
        {
          text: 'Status',
          width: '10%',
          sort: null,
        },
        {
          text: 'Action',
          width: '10%',
          sort: null,
        },
      ],
      resTableHeaders: [
        { text: '', width: '10%', sort: null },

        {
          text: 'Rider Name',
          width: '40%',
          sort: null,
        },
        {
          text: 'Requested Amount',
          width: '30%',
          sort: null,
        },

        {
          text: 'Status',
          width: '10%',
          sort: null,
        },
      ],
    }
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  methods: {
    onApplyFilterDateRange,
    truncate,
    onRefundReq(data) {
      this.$modal.show('RefundRequestPopup')
      EventBus.$emit(RefundRequestConfig.events.refundRequestPopup, data)
    },
    getVariant(status) {
      if (status === 0) {
        return 'orange'
      } else if (status === 1) {
        return 'green'
      } else {
        return 'red'
      }
    },
    copyTextToClipboard(link) {
      const el = document.createElement('textarea')
      el.value = link
      el.setAttribute('readonly', '')
      el.style.position = 'absolute'
      el.style.left = '-99999px'
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      this.$notify(
        {
          group: 'generic',
          type: 'success',
          title: 'Payment Reference link Copied',
          text: 'The Payment Reference link has been copied to your clipboard',
        },
        2000
      )
    },
    onResize() {
      this.windowWidth = window.innerWidth
    },
    toggle(id) {
      const index = this.opened.indexOf(id)
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened.push(id)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.left-text {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #3a4048;
}
.right-text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #6d6d75;
}
</style>
